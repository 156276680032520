import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'
import SEO from '../components/SEO'
import Pagination from '../components/Pagination'
import PostCardList from '../components/PostCardList'
import PostCard from '../components/PostCard'

const Posts = ({ data, pageContext, ...props }) => {
  // console.log('>>> DATA')
  // console.log(data)
  // console.log('>>> CONTEXT')
  // console.log(pageContext)
  // console.log('>>> PROPS')
  // console.log(props)

  const posts = data.allContentfulPost.edges
  const { humanPageNumber } = pageContext
  const isFirstPage = humanPageNumber === 1 || false

  return (
    <Layout>
      <SEO title={null} />  

      <Container>
        <section>
          {/* <Pagination context={pageContext} /> */}
          <PostCardList>
            {
              posts.map(({ node: post }, ndx) => (
                <PostCard 
                  key={post.id} 
                  featured={isFirstPage && ndx===0}
                  data={post}
                />              
              ))
            }
          </PostCardList>         
          <Pagination context={pageContext} />
        </section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulPost(
      limit: $limit
      skip: $skip
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug 
          description {
            description
          }         
          body {
            json
          }
          topic {
            slug
          }
          heroImage {
            title
            fluid(maxWidth: 1600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            sm: resize(height: 800) {
              src
            }
          }    
          publishDate(formatString: "MMMM Do, YYYY")
        }
      }
    }
  }
`

export default Posts
